<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import moment from 'moment';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesLoadingOpen = ref(false)

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesLoadingOpen
        };
    },
    page: {
    title: "transaction",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "transaction",
      items: [
        {
          text: "Menu",
        },
        {
          text: "transaction",
          active: true,
        },
      ],
      errors: [],
      transactions: [],
      transaction_search: [],
      state: "",
      states: [{
        "key": "TOUT"
      }, {
        "key": "EN ATTENTE"
      }, {
        "key": "PROUVEE"
      }, {
        "key": "EN COURS"
      }, {
        "key": "TRANSFERER"
      }, {
        "key": "TERMEINE"
      }, {
        "key": "ECHOUE"
      }],
      search: '',
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      let route = "transactions/managers"
      if (localStorage.getItem('role') === "ADMIN") {
        route = 'transactions/'
      }
      fetch(Helper.route(route), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        
        this.transactions = data
        this.doesLoadingOpen = false
        this.transaction_search = data
      })
    },
    filter_transaction() {
      this.transactions = this.transaction_search
      if (this.search != "") {
        this.transactions = this.transactions.filter(transaction => (
          transaction.transaction_id == this.search || 
          transaction.name == this.search
        )) 
      }
    },
    convertDate(date) {
      return moment.utc(date).format('DD/MM/YYYY ') + " à " + moment.utc(date).format(' H:mm')
    }
  },
  mounted() {
    this.loadList()
    this.doesLoadingOpen = false
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3" v-show="status != 403">
      <div class="col-md-4">
        <input type="search" class="form-control" v-model="search" v-on:input="filter_transaction()" placeholder="Rechercher une transaction">
      </div>
      <div class="col-md-2 ms-auto">
        <select name="" id="" v-model="state" class="form-control text-center bg-secondary text-white">
          <option value="">Statut</option>
          <option v-for="state in states" :key="state.key" :value="state.key">{{ state.key }}</option>
        </select>
      </div>
    </div>

    <div class="row" v-show="status != 403">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des transactions</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>transactions</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="bg-light rounded">
                  <tr>
                    <th>#</th>
                    <th>iD</th>
                    <th>Nom</th>
                    <th>Date</th>
                    <th>Statut</th>
                    <th>Voir Detail</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, i) in transactions" :key="transaction.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ transaction.transaction_id }}</td>
                    <td>{{ transaction.name }}</td>
                    <td>{{ convertDate(transaction.created_at) }}</td>
                    <td>
                      <span v-if="transaction.status == 'EN ATTENTE' || transaction.status == 'CANCEL'" class="badge badge-pill badge-soft-danger font-size-11">
                          {{ transaction.status }}
                      </span>
                      <span v-if="transaction.status == 'PROUVEE' || transaction.status == 'EN COURS'" class="badge badge-pill badge-soft-warning font-size-11">
                          {{ transaction.status }}
                      </span>
                      <span v-if="transaction.status == 'ECHOUE'" class="badge badge-pill badge-soft-danger font-size-11">
                          {{ transaction.status }}
                      </span>
                      <span v-if="transaction.status == 'TERMEINE' || transaction.status == 'TRANSFERER'" class="badge badge-pill badge-soft-success font-size-11">
                          {{ transaction.status }}
                      </span>
                    </td>
                    <td>
                      <router-link class="rounded-pill btn-sm btn-primary px-3" :to="'/transactions/'+transaction.id">Voir detail</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div>


    <div v-show="status === 403">
      <h3 class="text-danger text-center my-5">Vous n'êtes pas encore ajouté, demandez à l'administrateur principal.</h3>
    </div>

    <!-- LOADING MODAL -->
    <b-modal v-model="doesLoadingOpen" id="modal-center" centered size="md" title="Chargement..."
      title-class="font-18" hide-footer>
      <div class="text-center">
        <div class="spinner-border text-primary-" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </b-modal>

  </Layout>
</template>
